import React, { createContext, useReducer } from 'react'
import { ACTIONS } from './actions'
import GlobalReducer from './reducer'
import moment from 'moment'
import { NUTS } from '../../assets/NUTS3'
import 'moment/locale/de'
moment.locale('de')

/* Initial State */
const initialState = {
    analysisType: 'trip',

    zipData: NUTS,

    npgeoData: [],
    npgeoDataLoading: false,

    from: {
        placeID: '',
        zip: '',
        name: '',
        sublocality: '',
        coordinates: {},
        date: moment(),
    },

    to: {
        placeID: '',
        zip: '',
        name: '',
        sublocality: '',
        coordinates: {},
        date: moment().add(1, 'd')
    },

    filteredFrom: [],
    filteredTo: [],

    email: '',
    sessionID: '',

    bodyHeight: 0,
    activeTab: 0,
    errors: []
}

/* Context */
export const GlobalContext = createContext(initialState)

/* Provider */
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(GlobalReducer, initialState)

    function setNPGEOData(data) {
        dispatch({
            type: ACTIONS.SET_NPGEO_DATA,
            payload: data
        })
    }

    function setAnalysisType(type) {
        dispatch({
            type: ACTIONS.SET_ANALYSIS_TYPE,
            payload: type
        })
    }

    function updateFrom(fromObj) {
        dispatch({
            type: ACTIONS.UPDATE_FROM,
            payload: fromObj
        })
    }

    function updateTo(toObj) {
        dispatch({
            type: ACTIONS.UPDATE_TO,
            payload: toObj
        })
    }

    function setFilteredFrom(arr) {
        dispatch({
            type: ACTIONS.SET_FILTERED_FROM,
            payload: arr
        })
    }

    function setFilteredTo(arr) {
        dispatch({
            type: ACTIONS.SET_FILTERED_TO,
            payload: arr
        })
    }

    function setEmail(email) {
        dispatch({
            type: ACTIONS.SET_EMAIL,
            payload: email
        })
    }

    function setSessionID(sessionID) {
        dispatch({
            type: ACTIONS.SET_SESSION_ID,
            payload: sessionID
        })
    }

    function setBodyHeight(height) {
        dispatch({
            type: ACTIONS.SET_BODY_HEIGHT,
            payload: height
        })
    }

    function setActiveTab(tabIndex) {
        dispatch({
            type: ACTIONS.SET_ACTIVE_TAB,
            payload: tabIndex
        })
    }

    function setErrors(err) {
        dispatch({
            type: ACTIONS.SET_ERRORS,
            payload: err
        })
    }

    return (
        <GlobalContext.Provider value={{
            analysisType: state.analysisType, setAnalysisType,

            zipData: state.zipData,

            npgeoData: state.npgeoData, setNPGEOData,
            npgeoDataLoading: state.npgeoDataLoading,

            from: state.from, updateFrom,
            to: state.to, updateTo,

            filteredFrom: state.filteredFrom, setFilteredFrom,
            filteredTo: state.filteredTo, setFilteredTo,

            email: state.email, setEmail,
            sessionID: state.sessionID, setSessionID,

            bodyHeight: state.bodyHeight, setBodyHeight,
            activeTab: state.activeTab, setActiveTab,
            errors: state.errors, setErrors,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './tabresult.module.scss'

import { Errors } from '../../components/Errors'
import { ResultBox } from '../../components/ResultBox'
import { BackButton, ResetButton } from '../../components/Buttons'

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'

import { APIService } from '../../services'
import { EmailField } from '../../components/EmailField'

export const TabResult = () => {

    const {
        analysisType,
        from,
        to,
        filteredFrom,
        filteredTo
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    return (
        <>
            <BackButton />
            <h2>
                {isTrip ? 'Dein aktueller Reisestatus' : 'Aktueller Status'}
            </h2>

            <Errors />

            <div className={styles.wrapper}>
                <div className={styles.result}>
                    {filteredFrom.map((location, i) =>
                        <ResultBox key={i} data={{ ...location.attributes, location: from }} />
                    )}
                </div>
                {isTrip ?
                    <>
                        <div className={styles.arrowTo}>
                            <ArrowForwardRoundedIcon />
                        </div>
                        <div className={styles.result}>
                            {filteredTo.map((location, i) =>
                                <ResultBox key={i} data={{ ...location.attributes, location: to }} />
                            )}
                        </div>
                    </>
                    : ''}
            </div>

            <h3>{isTrip ? 'Updates zu Deiner Reise erhalten?' : 'Updates erhalten'}</h3>
            <p>Du möchtest über Änderungen auf dem Laufenden gehalten werden? Dann kannst Du hier jetzt Deine E-Mail hinterlegen, und wir informieren Dich, sobald es zu relevanten Änderungen kommt!</p>

            <EmailField />

            <div style={{ margin: '2rem auto', display: 'flex' }}>
                <ResetButton style={{ flex: '1' }} />
            </div>
        </>
    )
}

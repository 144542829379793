import React, { useContext } from 'react'
import { ThemeContext } from '../../../context/Theme'
import generalstyles from '../input.module.scss'
import styles from './colorinput.module.scss'

export const ColorInput = (props) => {

    const { options } = useContext(ThemeContext)

    return (
        <div style={props.style} className={[props.error ? generalstyles.error : '', props.children ? generalstyles.hasIcon : ''].join(' ')}>
            <label className={styles.label}
                style={{
                    backgroundColor: props.value || '#fff',
                }}>
                <input
                    className={styles.input}
                    type="color"
                    value={props.value}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                />
                {props.children}
            </label>
        </div>
    )
}
import React, { useContext } from 'react'
import styles from './tabs.module.scss'

import BrushIcon from '@material-ui/icons/Brush';
import MailIcon from '@material-ui/icons/Mail';
import { GlobalContext } from '../../context/Global';

export const Tabs = ({ active, setActive }) => {

    const { analysisType, setAnalysisType } = useContext(GlobalContext)

    const handleClick = (tab) => {
        if (tab === 'style') {
            setActive('style')
            setAnalysisType('trip')
        } else {
            setActive('mail')
            setAnalysisType('live')
        }
    }

    return (
        <div className={styles.tabs}>
            <div className={[styles.tab, active === 'style' ? styles.active : ''].join(' ')} onClick={() => handleClick('style')}>
                <BrushIcon />
                <span>Style</span>
            </div>
            <div className={[styles.tab, active === 'mail' ? styles.active : ''].join(' ')} onClick={() => handleClick('mail')}>
                <MailIcon />
                <span>Mail</span>
            </div>
        </div>
    )
}

import React from 'react'
import styles from './button.module.scss'
import { Loader } from '../../Loader'

export const Button = (props) => {

    return (
        <button
            className={[styles.button, props.children ? styles.hasIcon : '', props.loading ? styles.loading : '', props.className].join(' ')}
            onClick={props.onClick}
            style={props.style}
            disabled={props.disabled || props.loading}
        >
            <span>
                {props.children}
                {props.text}
            </span>
            {props.loading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                : ''}
        </button>
    )
}
import React from 'react'
import generalstyles from '../input.module.scss'
// import styles from './textinput.module.scss'

export const TextInput = (props) => {

    return (
        <div style={props.style} className={[generalstyles.inputwrapper, props.error ? generalstyles.error : '', props.children ? generalstyles.hasIcon : '', props.className ? props.className : ''].join(' ')}>
            <input
                className={generalstyles.input}
                type="text"
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
            {props.children}
        </div>
    )
}
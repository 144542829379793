import React, { useState, useEffect } from 'react'
import styles from './emailfield.module.scss'
import { TextInput } from '../../Inputs'
import { useDebounce } from '../../../hooks'
import { validateEmail } from '../../../helpers/validateEmail'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

export const EmailInput = (props) => {

    const [loading, setLoading] = useState(false)
    const [validEmail, setValidEmail] = useState(true)

    const [inputEmail, setInputEmail] = useState('')
    const debouncedEmail = useDebounce(inputEmail, 250)

    useEffect(() => {
        props.setEmail(debouncedEmail)
    }, [debouncedEmail])

    const handleEmail = async () => {
        const validEmail = validateEmail(props.email)
        if (validEmail) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    }

    return (
        <>
            <TextInput
                placeholder="E-Mail eingeben..."
                onChange={(e) => setInputEmail(e.target.value)}
                error={!validEmail}
                style={{ flex: '1' }}
                className={props.className}
            >
                <EmailRoundedIcon />
            </TextInput>
        </>
    )
}

import React, { useState, useEffect, useContext, useRef } from 'react'
import { Tabs } from './tabs'
import { Button } from '../components/Buttons'
import { ColorInput } from '../components/Inputs/ColorInput'
import { SingleLocationInput } from '../components/Inputs/LocationInput/SingleLocationInput'
import { RangeInput } from '../components/Inputs/RangeInput'
import { GlobalContext } from '../context/Global'
import { ThemeContext } from '../context/Theme'
import styles from './customizer.module.scss'
import { EmailInput } from '../components/Inputs/EmailInput'
import { TextArea } from '../components/Inputs/TextArea'
import { TextInput } from '../components/Inputs'
import { APIService, LocationService } from '../services'
import BerlinCodes from '../assets/BerlinZipCodes.json'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const Customizer = () => {

  const BASE_URL = process.env.REACT_APP_BASE_URL
  const BASE_URL_WEBSITE = process.env.REACT_APP_BASE_URL_WEBSITE

  const createIFrameCode = (url) => `
    <iframe id="meinereise" src="${url}" style="border:0;width:100%;" allow="geolocation; clipboard-write"></iframe>
    <script defer src="https://cdn.meinereise.org/widget/meinereise.min.js"></script>
  `

  const {
    options,
    setOptions
  } = useContext(ThemeContext)

  const {
    analysisType,
    from,
    to,
    zipData,
    npgeoData,
    setFilteredFrom,
    setFilteredTo,
    setErrors
  } = useContext(GlobalContext)

  const isTrip = analysisType === 'trip'

  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)

  const [showCode, setShowCode] = useState(false)
  const [copyCodeLoading, setCopyCodeLoading] = useState(false)
  const [codeCopied, setCodeCopied] = useState(false)

  const [activeTab, setActiveTab] = useState('style')
  const [email, setEmail] = useState('')
  const [infoTitle, setInfoTitle] = useState('')
  const [infoText, setInfoText] = useState('')
  const [hasEmptyFields, setHasEmptyFields] = useState(false)
  const [hasSendingError, setHasSendingError] = useState(false)
  const [hasBeenSent, setHasBeenSent] = useState(false)

  const resRef = useRef(null)

  const createURL = () => {
    setLoading(true)
    setUrl('')
    setCopied(false)
    const params = new URLSearchParams({
      ...options,

      placeIDFrom: from.placeID,
      zipFrom: from.zip,
      nameFrom: from.name,
      sublocalityFrom: from.sublocality,
      coordinatesFromLat: from.coordinates.lat,
      coordinatesFromLng: from.coordinates.lng,

      placeIDTo: to.placeID,
      zipTo: to.zip,
      nameTo: to.name,
      sublocalityTo: to.sublocality,
      coordinatesToLat: to.coordinates.lat,
      coordinatesToLng: to.coordinates.lng,
    })
    const encodedParams = btoa(encodeURIComponent(params))
    setTimeout(() => {
      setLoading(false)
      setUrl(BASE_URL + '?' + encodedParams)
    }, 1000);
  }

  const copyCode = async () => {
    setCopyCodeLoading(true)
    try {
      await navigator.clipboard.writeText(createIFrameCode(url))
      setCodeCopied(true)
    } catch (error) {
      console.error(error)
      setCodeCopied(false)
    }
    setCopyCodeLoading(false)
  }

  const sendNotification = async () => {
    setHasSendingError(false)
    setHasBeenSent(false)
    setLoading(true)

    const params = new URLSearchParams({
      ...options,

      analysisType: 'trip',

      placeIDTo: from.placeID,
      zipTo: from.zip,
      nameTo: from.name,
      sublocalityTo: from.sublocality,
      coordinatesToLat: from.coordinates.lat,
      coordinatesToLng: from.coordinates.lng,
    })

    const encodedParams = btoa(encodeURIComponent(params))
    const infoURL = BASE_URL_WEBSITE + '?options=' + encodedParams

    if ((from.name.length > 0) && (email.length > 0) && (infoTitle.length > 0) && (infoText.length > 0)) {
      setHasEmptyFields(false)
      try {
        const rkiData = await APIService.getRKIData()
        const zips = await LocationService.checkZips(false, from, to, zipData, BerlinCodes, rkiData, setFilteredFrom, setFilteredTo, setErrors)
        const filteredFrom = zips[0]
        await APIService.addTripNotification(from, filteredFrom, email, infoTitle, infoText, infoURL)
        setLoading(false)
        setHasBeenSent(true)
      } catch (error) {
        console.error(error)
        setHasSendingError(true)
        setLoading(false)
        setHasBeenSent(false)
      }
    } else {
      setHasEmptyFields(true)
      setLoading(false)
    }
  }

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(url)
      setCopied(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (url.length > 0 && !loading) {
      resRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [url])

  useEffect(() => {
    setUrl('')
  }, [options, from, to])

  return (
    <div className={styles.wrapper}>
      <Tabs active={activeTab} setActive={setActiveTab} />

      {activeTab === 'style' ? (
        <>
          <div className={[styles.category, styles.centered].join(' ')}>
            <h2>Farbe</h2>
            <ColorInput
              value={options.mainColor}
              onChange={(e) => setOptions({ ...options, mainColor: e.target.value })}
            />
          </div>

          <div className={styles.category}>
            <h2>Wrapper</h2>
            <p style={{ marginBottom: '2rem' }}>
              Um die beste Kontrolle zu haben, empfehlen wir Dir beide Abstände auf 0 zu stellen und das Widget in einem von Dir erzeugten Wrapper zu packen.
                </p>
            <div className={styles.item}>
              <div>
                <span>Vertikaler Abstand</span>
                <span>{options.verticalPadding}</span>
              </div>
              <RangeInput
                value={options.verticalPadding.slice(0, -2)}
                min={0}
                max={100}
                onChange={(e) => setOptions({ ...options, verticalPadding: e.target.value + 'px' })}
              />
            </div>
            <div className={styles.item}>
              <div>
                <span>Horizontaler Abstand</span>
                <span>{options.horizontalPadding}</span>
              </div>
              <RangeInput
                value={options.horizontalPadding.slice(0, -2)}
                min={0}
                max={100}
                onChange={(e) => setOptions({ ...options, horizontalPadding: e.target.value + 'px' })}
              />
            </div>
          </div>

          <div className={styles.category}>
            <h2>Inputs</h2>
            <p style={{ marginBottom: '2rem' }}>
              Hier kannst du das Design und den Standardinhalt der Inputfelder anpassen. Beispielsweise macht es Sinn, als Zielort den Unternehmensstandort anzugeben.
                </p>
            <div className={styles.item}>
              <div>
                <span>Vertikaler Abstand</span>
                <span>{options.inputVerticalPadding}</span>
              </div>
              <RangeInput
                value={options.inputVerticalPadding.slice(0, -2)}
                min={0}
                max={50}
                onChange={(e) => setOptions({ ...options, inputVerticalPadding: e.target.value + 'px' })}
              />
            </div>
            <div className={styles.item}>
              <div>
                <span>Eckenradius</span>
                <span>{options.inputBorderRadius}</span>
              </div>
              <RangeInput
                value={options.inputBorderRadius.slice(0, -2)}
                min={0}
                max={50}
                onChange={(e) => setOptions({ ...options, inputBorderRadius: e.target.value + 'px' })}
              />
            </div>
            <div className={styles.item} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <div>
                <span>Startort</span>
                <span>{from.name}</span>
              </div>
              <SingleLocationInput direction="from" placeholder="Start" className={styles.location} />
            </div>
            <div className={styles.item} style={{ marginBottom: '1rem' }}>
              <div>
                <span>Zielort</span>
                <span>{to.name}</span>
              </div>
              <SingleLocationInput direction="to" placeholder="Ziel" className={styles.location} />
            </div>
          </div>

          <div className={styles.category}>
            <h2>Buttons</h2>
            <div className={styles.item}>
              <div>
                <span>Eckenradius</span>
                <span>{options.buttonRadius}</span>
              </div>
              <RangeInput
                value={options.buttonRadius.slice(0, -2)}
                min={0}
                max={50}
                onChange={(e) => setOptions({ ...options, buttonRadius: e.target.value + 'px' })}
              />
            </div>
          </div>

          <div className={styles.category}>
            <Button
              loading={loading}
              className={loading ? styles.loading : ''}
              text="Jetzt Link generieren"
              onClick={createURL}
            />
            {(url.length > 0) && !loading && (
              <div ref={resRef}>
                <p style={{ margin: '2rem 0' }}>
                  Der folgende Link beinhaltet Deine persönliche Konfiguration unseres Widgets. Diesen Link kannst Du in Zukunft anstelle des Standard-URLs verwenden. <a href="https://docs.meinereise.org/" target="_blank" rel="noreferrer">Hier</a> erfährst Du, wie Du unser Widget auf Deiner Website einbinden kannst.
                    </p>
                <div className={styles.urlwrapper}>
                  <input type="text" value={url} className={styles.url} onFocus={(e) => e.target.select()} readOnly />
                  <span className={styles.copy} onClick={copy}>
                    {copied ? 'Copied' : 'Copy'}
                  </span>
                </div>
                <div className={styles.copyCode}>
                  {codeCopied && (
                    <span style={{
                      display: 'inline-block',
                      width: '100%',
                      margin: '0 auto 1rem',
                      padding: '0.75rem 2rem',
                      backgroundColor: '#5c54b0',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}>
                      Code kopiert
                    </span>
                  )}
                  <Button
                    loading={copyCodeLoading}
                    className={copyCodeLoading ? styles.loading : ''}
                    text="Einbettungscode kopieren"
                    onClick={copyCode}
                  />
                  <span onClick={() => setShowCode(!showCode)} className={styles.showCode}>
                    {showCode ? 'Code verstecken' : 'Code anzeigen'}
                  </span>
                  {showCode && (
                    <div className={styles.code}>
                      <SyntaxHighlighter language="markup" style={vscDarkPlus}>
                        {createIFrameCode(url)}
                      </SyntaxHighlighter>
                    </div>
                  )}
                </div>
                {}
              </div>
            )}
          </div>
        </>
      ) : (
          <>
            <div className={styles.category}>
              <h2>Gäste informieren</h2>
              <p style={{ marginBottom: '2rem' }}>
                Hier hast Du vorübergehend die Möglichkeit, Gäste über E-Mail zu informieren. Dafür müsstest Du den Standort Deines Unternehmens, die E-Mail des zu informierenden Gastes, sowie die Information, welche der Gast per E-Mail erhalten soll, angeben.
              </p>
              <div className={styles.item} style={{ marginBottom: '1.75rem' }}>
                <div>
                  <span>Standort</span>
                  <span>{from.name}</span>
                </div>
                <SingleLocationInput direction="from" placeholder="Standort" className={styles.location} />
              </div>
              <div className={styles.item} style={{ marginBottom: '1.75rem' }}>
                <div>
                  <span>E-Mail des Gastes</span>
                  <span>{email}</span>
                </div>
                <EmailInput className={styles.email} setEmail={setEmail} />
              </div>
              <div className={styles.item} style={{ marginBottom: '1.75rem' }}>
                <div>
                  <span>Titel der E-Mail</span>
                  <span>{infoTitle}</span>
                </div>
                <TextInput
                  className={styles.textinput}
                  onChange={(e) => setInfoTitle(e.target.value)}
                  placeholder="z.B. Neue Informationen im Haffhus"
                />
              </div>
              <div className={styles.item} style={{ marginBottom: '2.25rem' }}>
                <div>
                  <span>Inhalt der E-Mail</span>
                </div>
                <TextArea setInfoText={setInfoText} rows={10} />
              </div>

              {hasEmptyFields && (
                <span style={{
                  display: 'inline-block',
                  width: '100%',
                  margin: '0 auto 2rem',
                  padding: '1.25rem 2rem',
                  backgroundColor: '#5c54b0',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}>
                  Es wurden nicht alle Felder ausgefüllt
                </span>
              )}

              {hasSendingError && (
                <span style={{
                  display: 'inline-block',
                  width: '100%',
                  margin: '0 auto 2rem',
                  padding: '1.25rem 2rem',
                  backgroundColor: '#5c54b0',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}>
                  Oops. Etwas ist schiefgelaufen. Bitte versuche es noch einmal.
                </span>
              )}

              {hasBeenSent && (
                <span style={{
                  display: 'inline-block',
                  width: '100%',
                  margin: '0 auto 2rem',
                  padding: '1.25rem 2rem',
                  backgroundColor: '#5c54b0',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}>
                  Benachrichtigung erfolgreich gesendet
                </span>
              )}

              <Button
                loading={loading}
                className={loading ? styles.loading : ''}
                text="Benachrichtigung senden"
                onClick={sendNotification}
              />


            </div>
          </>
        )}
    </div>
  )
}
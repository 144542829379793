import React from 'react'
import styles from './loader.module.scss'

export const Loader = () => {
    return (
        <div className={styles.loader}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

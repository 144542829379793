import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './emailfield.module.scss'
import { Button } from '../Buttons'
import { TextInput } from '../Inputs'
import { useDebounce } from '../../hooks'
import { validateEmail } from '../../helpers/validateEmail'
import { APIService } from '../../services'
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

export const EmailField = () => {

    const {
        analysisType,
        email, setEmail,
        from,
        sessionID,
        activeTab, setActiveTab
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    const [loading, setLoading] = useState(false)
    const [validEmail, setValidEmail] = useState(true)

    const [inputEmail, setInputEmail] = useState('')
    const debouncedEmail = useDebounce(inputEmail, 250)

    useEffect(() => {
        setEmail(debouncedEmail)
    }, [debouncedEmail])

    const addEmailToSession = async () => {
        setLoading(true)
        await APIService.addEmailToSession(email, sessionID, isTrip, from)
        setLoading(false)
    }

    const handleEmail = async () => {
        const validEmail = validateEmail(email)
        if (validEmail) {
            setValidEmail(true)
            await addEmailToSession()
            setActiveTab(activeTab + 1)
        } else {
            setValidEmail(false)
        }
    }

    return (
        <>
            <div className={[styles.wrapper, loading ? styles.loading : ''].join(' ')}>
                <TextInput
                    placeholder="E-Mail eingeben..."
                    onChange={(e) => setInputEmail(e.target.value)}
                    error={!validEmail}
                    style={{ flex: '1' }}
                >
                    <EmailRoundedIcon />
                </TextInput>
                <Button loading={loading} text='Senden' onClick={handleEmail}>
                    <SendRoundedIcon />
                </Button>
                {/* {!validEmail ?
                    <span>Die E-Mail-Adresse ist leider ungültig.</span>
                    : ''} */}
            </div>
        </>
    )
}

/**
 * @name LocationService
 * ---------------------
 * @description This service deals with incoming RKI and Google data
 * @todo !!! DEFINITELY NEEDS TO BE OPTIMIZED, CURRENTLY DIRTY CODE !!!
 */

export default class LocationService {

    static checkZips = (isTrip, from, to, zipData, BerlinCodes, npgeoData, setFilteredFrom, setFilteredTo, setErrors) => {
        return new Promise((resolve, reject) => {
            let errors = []

            // TRIP CHECK
            if (isTrip) {
                if (from.zip && to.zip) {

                    const filteredNutsFrom = zipData.filter(item => {
                        if (item.CODE) {
                            return item.CODE.includes(from.zip)
                        } else {
                            console.error('item.CODE of the following element is undefined:')
                            console.log(item)
                        }
                        return [];
                    })

                    const filteredNutsTo = zipData.filter(item => {
                        if (item.CODE) {
                            return item.CODE.includes(to.zip)
                        } else {
                            console.error('item.CODE of the following element is undefined:')
                            console.log(item)
                        }
                        return []
                    })

                    const NUTS3KEYSFROM = Array.from(new Set(filteredNutsFrom.map(e => e.NUTS3))) // Will cut out duplicates
                    const NUTS3KEYSTO = Array.from(new Set(filteredNutsTo.map(e => e.NUTS3))) // Will cut out duplicates

                    let filteredResultsFrom = []
                    let filteredResultsTo = []

                    if (NUTS3KEYSFROM.includes('DE300')) {
                        for (const district of BerlinCodes) {
                            for (const zip of district.zips) {
                                if (zip === +from.zip) {
                                    const resultsFrom = npgeoData.filter(item => item.attributes.RS.includes(district.rs))
                                    resultsFrom.forEach(res => filteredResultsFrom.push(res))
                                }
                            }
                        }
                    } else {
                        for (const NUTS3KEY of NUTS3KEYSFROM) {
                            const resultsFrom = npgeoData.filter(item => item.attributes.NUTS.includes(NUTS3KEY))
                            resultsFrom.forEach(res => filteredResultsFrom.push(res))
                        }
                    }

                    if (NUTS3KEYSTO.includes('DE300')) {
                        for (const district of BerlinCodes) {
                            for (const zip of district.zips) {
                                if (zip === +to.zip) {
                                    const resultsFrom = npgeoData.filter(item => item.attributes.RS.includes(district.rs))
                                    resultsFrom.forEach(res => filteredResultsTo.push(res))
                                }
                            }
                        }
                    } else {
                        for (const NUTS3KEY of NUTS3KEYSTO) {
                            const resultsTo = npgeoData.filter(item => item.attributes.NUTS.includes(NUTS3KEY))
                            resultsTo.forEach(res => filteredResultsTo.push(res))
                        }
                    }

                    if (filteredResultsFrom.length > 1) {
                        let backupResultsFrom = filteredResultsFrom

                        filteredResultsFrom = filteredResultsFrom.filter(filteredResult => {
                            return filteredResult.attributes.county.includes(from.sublocality.split(' ').pop())
                        })

                        if (!filteredResultsFrom.length > 0) {
                            filteredResultsFrom = backupResultsFrom
                        }
                    }

                    if (filteredResultsTo.length > 1) {
                        let backupResultsTo = filteredResultsTo

                        filteredResultsTo = filteredResultsTo.filter(filteredResult => {
                            return filteredResult.attributes.county.includes(to.sublocality.split(' ').pop())
                        })

                        if (!filteredResultsTo.length > 0) {
                            filteredResultsTo = backupResultsTo
                        }
                    }

                    if (filteredResultsFrom.length > 1 || filteredResultsTo.length > 1) {
                        errors.push('Mindestens ein angegebener Standort liegt in einem Postleitzahlbereich, welcher mehreren Kreisen zugeordnet wird.')
                    }

                    setFilteredFrom(filteredResultsFrom)
                    setFilteredTo(filteredResultsTo)
                    resolve([filteredResultsFrom, filteredResultsTo])

                } else {
                    errors.push('Ein Fehler ist aufgetreten. Die Standorte konnten nicht analysiert werden. Bitte versuche es erneut!')
                    console.error('No zip codes found')
                    reject('No zip codes found')
                }

                // LIVE CHECK
            } else {
                if (from.zip) {

                    const filteredNutsFrom = zipData.filter(item => {
                        if (item.CODE) {
                            return item.CODE.includes(from.zip)
                        } else {
                            console.error('item.CODE of the following element is undefined:')
                            console.log(item)
                            reject('item.CODE undefined')
                        }
                        return [];
                    })

                    const NUTS3KEYSFROM = Array.from(new Set(filteredNutsFrom.map(e => e.NUTS3))) // Will cut out duplicates

                    let filteredResultsFrom = []

                    if (NUTS3KEYSFROM.includes('DE300')) {
                        for (const district of BerlinCodes) {
                            for (const zip of district.zips) {
                                if (zip === +from.zip) {
                                    const resultsFrom = npgeoData.filter(item => item.attributes.RS.includes(district.rs))
                                    resultsFrom.forEach(res => filteredResultsFrom.push(res))
                                }
                            }
                        }
                    } else {
                        for (const NUTS3KEY of NUTS3KEYSFROM) {
                            const resultsFrom = npgeoData.filter(item => item.attributes.NUTS.includes(NUTS3KEY))
                            resultsFrom.forEach(res => filteredResultsFrom.push(res))
                        }
                    }

                    if (filteredResultsFrom.length > 1) {
                        let backupResultsFrom = filteredResultsFrom

                        filteredResultsFrom = filteredResultsFrom.filter(filteredResult => {
                            return filteredResult.attributes.county.includes(from.sublocality.split(' ').pop())
                        })

                        if (!filteredResultsFrom.length > 0) {
                            filteredResultsFrom = backupResultsFrom
                        }
                    }

                    if (filteredResultsFrom.length > 1) {
                        errors.push('Dein angegebener Standort liegt in einem Postleitzahlbereich, welcher mehreren Kreisen zugeordnet wird. Um fehlerhafte ')
                    }

                    setFilteredFrom(filteredResultsFrom)
                    resolve([filteredResultsFrom])

                } else {
                    errors.push('Ein Fehler ist aufgetreten. Die Standorte konnten nicht analysiert werden. Bitte versuche es erneut!')
                    console.error('No zip codes found')
                    reject('No zip codes found')
                }
            }

            setErrors(errors)
        })
    }
}
import React, { useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import { Box } from '../Box'

export const Errors = () => {

    const { errors } = useContext(GlobalContext)

    return (
        errors.length > 0 ?
            errors.map((error, i) =>
                <Box type='error' key={i}>
                    {error}
                </Box>
            )
            : ''
    )
}

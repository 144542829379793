import React, { useState, useContext, useEffect } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './tabinputs.module.scss'

import { APIService, LocationService } from '../../services'
import BerlinCodes from '../../assets/BerlinZipCodes.json'

import { Errors } from '../../components/Errors'
import { Tabs } from '../../components/Tabs'
import { Button } from '../../components/Buttons'
import { LocationInput } from '../../components/Inputs'
import { DatePicker } from '../../components/Inputs'


export const TabInputs = () => {

    const {
        analysisType,
        setErrors,
        setNPGEOData,
        zipData,
        from,
        to,
        setFilteredFrom,
        setFilteredTo,
        setSessionID,
        bodyHeight,
        activeTab, setActiveTab,
    } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)

    const isTrip = analysisType === 'trip'

    const errorMessages = {
        noZipCode: 'Bitte gib eine gültige Postleitzahl ein!',
        invalidZipCode: 'Wir konnten einem Deiner Orte leider keine eindeutige Postleitzahl zuordnen. Überprüfe Deine Eingabe oder wähle einen anderen Ort in der Nähe!',
        invalidDate: 'Bitte gib ein korrektes Datum ein!',
    }

    const checkInputsAndGoNext = async () => {
        setLoading(true)
        setErrors([])
        let errors = []

        // const rkiData = await APIService.getRKIData()
        // setNPGEOData(rkiData)

        // if (isTrip) {
        //     if ((!from.zip.length > 0) || (!to.zip.length > 0)) {
        //         errors.push(errorMessages.invalidZipCode)
        //     }

        //     if ((!from.date) || (!to.date)) {
        //         errors.push(errorMessages.invalidDate)
        //     }
        // } else {
        //     if ((!from.zip.length > 0)) {
        //         errors.push(errorMessages.invalidZipCode)
        //     }
        // }

        setTimeout(() => {
            errors.push('In der Vorschau ist leider keine Analyse verfügbar')
            if (errors.length > 0) {
                setErrors(errors)
                setLoading(false)
                return;
            }
        }, 1000);


        // const zips = await LocationService.checkZips(isTrip, from, to, zipData, BerlinCodes, rkiData, setFilteredFrom, setFilteredTo, setErrors)
        // const filteredFrom = zips[0]
        // const filteredTo = zips[1]
        // await APIService.addTrip(analysisType, from, to, filteredFrom, filteredTo, setSessionID)

        // setLoading(false)
        // setActiveTab(activeTab + 1)
    }

    return (
        <div className={styles.wrapper}>

            <Tabs />

            <Errors />

            <div className={styles.inputs}>
                <LocationInput />
                {isTrip ?
                    <DatePicker />
                    : ''}
            </div>

            <Button loading={loading} text={isTrip ? 'Reise analysieren' : 'Standort analysieren'} onClick={checkInputsAndGoNext} />


            <div className='expanding-iframe-fix' style={{ height: bodyHeight, pointerEvents: 'none', visibility: 'hidden' }}></div>

        </div>
    )
}
import React, { useState, useEffect } from 'react'
import styles from './textarea.module.scss'
import { useDebounce } from '../../../hooks'

export const TextArea = (props) => {

    const [inputText, setInputText] = useState('')
    const debouncedText = useDebounce(inputText, 250)

    useEffect(() => {
        props.setInfoText(debouncedText)
    }, [debouncedText])

    return (
        <div className={styles.wrapper}>
            <textarea
                onChange={(e) => setInputText(e.target.value)}
                rows={props.rows}
                cols={props.cols}
            ></textarea>
        </div>
    )
}

import React from 'react'
import generalstyles from '../input.module.scss'
import styles from './rangeinput.module.scss'

export const RangeInput = (props) => {

    return (
        <div style={props.style} className={[props.error ? generalstyles.error : '', props.children ? generalstyles.hasIcon : ''].join(' ')}>
            <input
                className={styles.input}
                type="range"
                value={props.value}
                min={props.min}
                max={props.max}
                step={props.step}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
            {props.children}
        </div>
    )
}
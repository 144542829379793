import React, { useContext } from 'react'
import { GlobalContext } from '../../../context/Global'
import styles from './locationinput.module.scss'

import { SwitchButton } from '../../Buttons'
import { SingleLocationInput } from './SingleLocationInput'

export const LocationInput = () => {

    const {
        analysisType,
        from, updateFrom,
        to, updateTo
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    const switchLocations = () => {
        let tmpFrom = from
        updateFrom({ ...to, date: from.date })
        updateTo({ ...tmpFrom, date: to.date })
    }

    return (
        <div className={styles.locationinputs}>
            <SingleLocationInput direction="from" placeholder={isTrip ? 'Start' : 'Ort eingeben ...'} />
            {isTrip ?
                <>
                    <SwitchButton onClick={switchLocations} />
                    <SingleLocationInput direction="to" placeholder="Ziel" />
                </>
                : ''}
        </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../../context/Global'
import inputstyles from '../input.module.scss'
import styles from './locationinput.module.scss'
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded'

import useOnclickOutside from 'react-cool-onclickoutside'

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getZipCode
} from "use-places-autocomplete";

export const SingleLocationInput = (props) => {

    const {
        analysisType,
        from, updateFrom,
        to, updateTo,
        options,
        setBodyHeight,
    } = useContext(GlobalContext)

    const ref = useOnclickOutside(() => {
        clearSuggestions();
    });

    const [showSuggestions, setShowSuggestions] = useState(true)
    const [focus, setFocus] = useState(false)
    const [hasError, setHasError] = useState(false)

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {
                country: 'de'
            }
        },
        debounce: 250,
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        setHasError(false)
        setShowSuggestions(true)
        setValue(e.target.value);
    };

    const handleSelect = (obj) => async () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(obj.description, false);
        clearSuggestions();

        const parameter = {
            placeId: obj.place_id,
        }

        try {
            const res = await getGeocode(parameter)
            const zipCode = await getZipCode(res[0], false)
            const latlng = await getLatLng(res[0])

            let sublocalityFrom = ''
            let sublocalityTo = ''

            for (const item of res[0].address_components) {
                if (item.types.includes('sublocality')) {
                    if (props.direction === 'from') {
                        sublocalityFrom = item.long_name
                    } else {
                        sublocalityTo = item.long_name
                    }
                }
            }

            if (zipCode) {
                if (props.direction === 'from') {
                    updateFrom({
                        ...from,
                        placeID: obj.place_id,
                        zip: zipCode,
                        name: obj.description,
                        sublocality: sublocalityFrom,
                        coordinates: latlng
                    })
                } else {
                    updateTo({
                        ...to,
                        placeID: obj.place_id,
                        zip: zipCode,
                        name: obj.description,
                        sublocality: sublocalityTo,
                        coordinates: latlng
                    })
                }
            } else {
                //setHasError(true)

                const locationParams = {
                    location: latlng
                }

                const resFromLatLng = await getGeocode(locationParams)

                for (const item of resFromLatLng) {
                    if (item.types.includes('postal_code')) {
                        if (props.direction === 'from') {
                            updateFrom({
                                ...from,
                                placeID: obj.place_id,
                                zip: item.address_components[0].short_name,
                                name: obj.description,
                                sublocality: sublocalityFrom,
                                coordinates: latlng
                            })
                        } else {
                            updateTo({
                                ...to,
                                placeID: obj.place_id,
                                zip: item.address_components[0].short_name,
                                name: obj.description,
                                sublocality: sublocalityTo,
                                coordinates: latlng
                            })
                        }
                    }
                }

            }

        } catch (error) {
            console.log("Error: ", error);
        }

    };

    const renderSuggestions = () => {
        return data.map((suggestion, i) => {
            const {
                //id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={i} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong>
                    <small>{secondary_text}</small>
                </li>
            );
        });
    }

    /* Handle Switch */
    useEffect(() => {
        if (props.direction === 'from') {
            if (value !== from.name) {
                setValue(from.name)
            }
        } else {
            if (value !== to.name) {
                setValue(to.name)
            }
        }
        setShowSuggestions(false)
    }, [from.name, to.name])


    /* IFrame Fix */
    useEffect(() => {
        if (focus) {
            analysisType === 'trip' ? setBodyHeight(0) : setBodyHeight(0)
        } else {
            setBodyHeight(0)
        }

        return (() => setBodyHeight(0))
    }, [focus])

    return (
        <div ref={ref} className={[inputstyles.inputwrapper, styles.singleinput, hasError ? inputstyles.error : '', props.className].join(' ')}>
            {props.label && props.id && <label htmlFor={props.id}>{props.label}</label>}
            <input
                id={props.id}
                className={inputstyles.input}
                type="text"
                value={value}
                onChange={handleInput}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                disabled={!ready}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                placeholder={props.placeholder}
            />
            <LocationOnRoundedIcon />
            {status === "OK" && showSuggestions &&
                <ul className={styles.options}>
                    {renderSuggestions()}
                </ul>
            }
        </div>
    )
}